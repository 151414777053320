<template>
  <div class="home">
    <van-nav-bar title="首页" />
    <van-grid :column-num="2">
      <van-grid-item :key="1" icon="bar-chart-o" text="项目面板" @click="navTo('projectdetail')" />
      <van-grid-item :key="2" icon="shrink" text="项目管理" @click="navTo('Project')" />
    </van-grid>
    <van-grid :column-num="2">
      <van-grid-item :key="1" icon="info-o" text="项目报工详情" @click="navTo('Tasklist')" />
      <van-grid-item :key="2" icon="cluster-o" text="项目信息汇总" @click="navTo('ProjectList')" />
    </van-grid>
    <van-grid :column-num="1">
      <van-grid-item :key="2" icon="close" text="登出" @click="logout" />
    </van-grid>
  </div>
</template>
<script>
import Api from "@/api/login.js";
export default {
  name: "Home",
  components: {},
  methods: {
    navTo(name) {
      this.$router.push({
        name: name
      });
    },
    logout() {
      Api.logout().then(res => {
        if (res.code == 200) {
          this.$store.dispatch("user/removeToken");
          this.$router.replace({
            name: "Login"
          });
        }
      });
    }
  },
  created() {}
};
</script>
