import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/login',
  name: 'Login',
  component: () => import('../views/login/Login.vue')
},
{
  path: '/project',
  name: 'Project',
  component: () => import('../views/project/Project.vue')
},
{
  path: '/qrcode',
  name: 'Qrcode',
  component: () => import('../views/qrcode/QrcodeReader.vue')
},
//项目信息汇总
{
  path: '/projectlist',
  name: 'ProjectList',
  component: () => import('../views/project/ProjectList.vue')
},
{
  path: '/summarize/:no',
  name: 'Summarize',
  component: () => import('../views/project/Summarize.vue')
},
//项目报工详情
{
  path: '/tasklist/:id',
  name: 'Tasklist',
  component: () => import('../views/task/Tasklist.vue')
},
{
  path: '/task/:no',
  name: 'Task',
  meta: { auth: false },
  component: () => import('../views/task/Task.vue')
},
//项目明细
{
  path: '/projectdetail',
  name: 'projectdetail',
  meta: { auth: false },
  component: () => import('../views/project/Projectdetail.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
