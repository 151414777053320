// 按需全局引入 vant组件
import Vue from 'vue'
import {
  NavBar,
  Form,
  Field,
  Button,
  Grid,
  GridItem,
  List,
  Row,
  Search,
  Col,
  Cell,
  CellGroup,
  dialog,
  RadioGroup,
  Radio,
  Popup,
  Uploader,
  Overlay,
  Icon,
  Image as VanImage,
  Empty
} from 'vant'
import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Search);
Vue.use(Button);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(List);
Vue.use(Row);
Vue.use(Col);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(VanImage);
Vue.use(Overlay);
Vue.use(VueQrcodeReader);
Vue.use(Icon);
Vue.use(Empty);
